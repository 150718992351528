<template>
  <div>
    <h2 class="mb-3">Purchased Courses</h2>
    <v-sheet outlined>
      <v-data-table
        :headers="tableHeaders"
        :items="member.courses"
        no-data-text="This user has not purchased any courses"
      >
        <template v-slot:item.amount="{ item }"> £{{ item.price }} </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: "Course", value: "name" },
        { text: "Purchase Date", value: "pivot.formatted_dates.purchase_date" },
        { text: "Amount", value: "amount" },
      ],
    };
  },
};
</script>